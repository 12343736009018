/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from './types.generated'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export const ErrorFragmentFragmentDoc = gql`
  fragment ErrorFragment on Error {
    field
    code
    message
  }
`
export const IntegrationFragmentFragmentDoc = gql`
  fragment IntegrationFragment on Integration {
    id
    code
    active
  }
`
export const NavigationStoreFragmentFragmentDoc = gql`
  fragment NavigationStoreFragment on Store {
    id
    name
    logo {
      url
    }
  }
`
export const CropsterOrderableProductsFragmentFragmentDoc = gql`
  fragment CropsterOrderableProductsFragment on CropsterOrderableProduct {
    id
    name
  }
`
export const PageInfoFragmentFragmentDoc = gql`
  fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`
export const ProductFragmentFragmentDoc = gql`
  fragment ProductFragment on Product {
    id
    name
  }
`
export const ManufacturerFragmentFragmentDoc = gql`
  fragment ManufacturerFragment on Manufacturer {
    id
    name
  }
`
export const MediaFragmentFragmentDoc = gql`
  fragment MediaFragment on Media {
    mediaType
    url
  }
`
export const CategoryFragmentFragmentDoc = gql`
  fragment CategoryFragment on Category {
    id
    slug
    name
    path
    icon
  }
`
export const StoreFragmentFragmentDoc = gql`
  fragment StoreFragment on Store {
    id
    name
    legalName
    includeTaxesInPrices
    logo {
      url
    }
    categories(language: $language) {
      ...CategoryFragment
    }
  }
  ${CategoryFragmentFragmentDoc}
`
export const MoneyFragmentFragmentDoc = gql`
  fragment MoneyFragment on Money {
    amount
    currency
  }
`
export const TaxedMoneyFragmentFragmentDoc = gql`
  fragment TaxedMoneyFragment on TaxedMoney {
    currency
    gross {
      ...MoneyFragment
    }
    net {
      ...MoneyFragment
    }
    tax {
      ...MoneyFragment
    }
  }
  ${MoneyFragmentFragmentDoc}
`
export const ProductAvailabilityFragmentFragmentDoc = gql`
  fragment ProductAvailabilityFragment on ProductAvailability {
    quantity
    leadTimeDays
    availabilityType
    fulfillmentTime {
      id
      value
      fulfillmentTimeType
    }
  }
`
export const WeightFragmentFragmentDoc = gql`
  fragment WeightFragment on Weight {
    unit
    value
  }
`
export const RelatedProductFragmentFragmentDoc = gql`
  fragment RelatedProductFragment on RelatedProduct {
    description
    value
    current
    product {
      id
      slug
      name
      bodyHtml
      manufacturer {
        ...ManufacturerFragment
      }
      media {
        ...MediaFragment
      }
      offers {
        sku
        store {
          ...StoreFragment
        }
        catalogPrice {
          ...MoneyFragment
        }
        sellingPrice {
          ...TaxedMoneyFragment
        }
        discountRate
        quantity
        availability {
          ...ProductAvailabilityFragment
        }
      }
      subscribed
      weight {
        ...WeightFragment
      }
    }
  }
  ${ManufacturerFragmentFragmentDoc}
  ${MediaFragmentFragmentDoc}
  ${StoreFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${ProductAvailabilityFragmentFragmentDoc}
  ${WeightFragmentFragmentDoc}
`
export const StoreShippingMethodFragmentFragmentDoc = gql`
  fragment StoreShippingMethodFragment on StoreShippingMethod {
    id
    expectedShippingTime
    shipTodayByHour
    timezone
    expectedShippingDate
    expectedDeliveryDate
  }
`
export const TaxGroupFragmentFragmentDoc = gql`
  fragment TaxGroupFragment on TaxGroup {
    id
    name
    rate
    code
  }
`
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    email
    phone
    firstName
    lastName
    fullName
  }
`
export const AcceptInvitationDocument = gql`
  mutation acceptInvitation($invitationToken: String!) {
    acceptInvitation(invitationToken: $invitationToken) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type AcceptInvitationMutationFn = Apollo.MutationFunction<
  Types.AcceptInvitationMutation,
  Types.AcceptInvitationMutationVariables
>

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AcceptInvitationMutation, Types.AcceptInvitationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.AcceptInvitationMutation, Types.AcceptInvitationMutationVariables>(
    AcceptInvitationDocument,
    options
  )
}
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>
export type AcceptInvitationMutationResult = Apollo.MutationResult<Types.AcceptInvitationMutation>
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<
  Types.AcceptInvitationMutation,
  Types.AcceptInvitationMutationVariables
>
export const UpdateBaselinkerConfigurationDocument = gql`
  mutation UpdateBaselinkerConfiguration($input: BaselinkerConfigurationUpdateInput!) {
    updateBaselinkerConfiguration(input: $input) {
      baselinkerConfiguration {
        id
        apiKey
        newOrderStatusId
        integration {
          ...IntegrationFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`
export type UpdateBaselinkerConfigurationMutationFn = Apollo.MutationFunction<
  Types.UpdateBaselinkerConfigurationMutation,
  Types.UpdateBaselinkerConfigurationMutationVariables
>

/**
 * __useUpdateBaselinkerConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateBaselinkerConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBaselinkerConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBaselinkerConfigurationMutation, { data, loading, error }] = useUpdateBaselinkerConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBaselinkerConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateBaselinkerConfigurationMutation,
    Types.UpdateBaselinkerConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateBaselinkerConfigurationMutation,
    Types.UpdateBaselinkerConfigurationMutationVariables
  >(UpdateBaselinkerConfigurationDocument, options)
}
export type UpdateBaselinkerConfigurationMutationHookResult = ReturnType<
  typeof useUpdateBaselinkerConfigurationMutation
>
export type UpdateBaselinkerConfigurationMutationResult =
  Apollo.MutationResult<Types.UpdateBaselinkerConfigurationMutation>
export type UpdateBaselinkerConfigurationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateBaselinkerConfigurationMutation,
  Types.UpdateBaselinkerConfigurationMutationVariables
>
export const BulkCreateProductDraftVariantsDocument = gql`
  mutation BulkCreateProductDraftVariants($input: [ProductDraftVariantCreateInput!]!) {
    bulkCreateProductDraftVariants(input: $input) {
      productDraftVariants {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type BulkCreateProductDraftVariantsMutationFn = Apollo.MutationFunction<
  Types.BulkCreateProductDraftVariantsMutation,
  Types.BulkCreateProductDraftVariantsMutationVariables
>

/**
 * __useBulkCreateProductDraftVariantsMutation__
 *
 * To run a mutation, you first call `useBulkCreateProductDraftVariantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateProductDraftVariantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateProductDraftVariantsMutation, { data, loading, error }] = useBulkCreateProductDraftVariantsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateProductDraftVariantsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BulkCreateProductDraftVariantsMutation,
    Types.BulkCreateProductDraftVariantsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.BulkCreateProductDraftVariantsMutation,
    Types.BulkCreateProductDraftVariantsMutationVariables
  >(BulkCreateProductDraftVariantsDocument, options)
}
export type BulkCreateProductDraftVariantsMutationHookResult = ReturnType<
  typeof useBulkCreateProductDraftVariantsMutation
>
export type BulkCreateProductDraftVariantsMutationResult =
  Apollo.MutationResult<Types.BulkCreateProductDraftVariantsMutation>
export type BulkCreateProductDraftVariantsMutationOptions = Apollo.BaseMutationOptions<
  Types.BulkCreateProductDraftVariantsMutation,
  Types.BulkCreateProductDraftVariantsMutationVariables
>
export const CreateProductDraftVariantDocument = gql`
  mutation createProductDraftVariant($input: ProductDraftVariantCreateInput!) {
    createProductDraftVariant(input: $input) {
      productDraftVariant {
        id
        name
        slug
        inventoryItem {
          id
          sku
          createdAt
          updatedAt
          basePrice {
            ...MoneyFragment
          }
          price {
            ...TaxedMoneyFragment
          }
        }
        weight {
          unit
          value
        }
        images {
          url
          id
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${MoneyFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`
export type CreateProductDraftVariantMutationFn = Apollo.MutationFunction<
  Types.CreateProductDraftVariantMutation,
  Types.CreateProductDraftVariantMutationVariables
>

/**
 * __useCreateProductDraftVariantMutation__
 *
 * To run a mutation, you first call `useCreateProductDraftVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductDraftVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductDraftVariantMutation, { data, loading, error }] = useCreateProductDraftVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductDraftVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateProductDraftVariantMutation,
    Types.CreateProductDraftVariantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateProductDraftVariantMutation, Types.CreateProductDraftVariantMutationVariables>(
    CreateProductDraftVariantDocument,
    options
  )
}
export type CreateProductDraftVariantMutationHookResult = ReturnType<typeof useCreateProductDraftVariantMutation>
export type CreateProductDraftVariantMutationResult = Apollo.MutationResult<Types.CreateProductDraftVariantMutation>
export type CreateProductDraftVariantMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateProductDraftVariantMutation,
  Types.CreateProductDraftVariantMutationVariables
>
export const UpdateCropsterConfigurationDocument = gql`
  mutation UpdateCropsterConfiguration($input: CropsterConfigurationUpdateInput!) {
    updateCropsterConfiguration(input: $input) {
      cropsterConfiguration {
        id
        orderableProducts {
          ...CropsterOrderableProductsFragment
        }
        integration {
          ...IntegrationFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${CropsterOrderableProductsFragmentFragmentDoc}
  ${IntegrationFragmentFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`
export type UpdateCropsterConfigurationMutationFn = Apollo.MutationFunction<
  Types.UpdateCropsterConfigurationMutation,
  Types.UpdateCropsterConfigurationMutationVariables
>

/**
 * __useUpdateCropsterConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateCropsterConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCropsterConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCropsterConfigurationMutation, { data, loading, error }] = useUpdateCropsterConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCropsterConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCropsterConfigurationMutation,
    Types.UpdateCropsterConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateCropsterConfigurationMutation,
    Types.UpdateCropsterConfigurationMutationVariables
  >(UpdateCropsterConfigurationDocument, options)
}
export type UpdateCropsterConfigurationMutationHookResult = ReturnType<typeof useUpdateCropsterConfigurationMutation>
export type UpdateCropsterConfigurationMutationResult = Apollo.MutationResult<Types.UpdateCropsterConfigurationMutation>
export type UpdateCropsterConfigurationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCropsterConfigurationMutation,
  Types.UpdateCropsterConfigurationMutationVariables
>
export const DeleteProductDraftDocument = gql`
  mutation deleteProductDraft($id: ID!) {
    deleteProductDraft(id: $id) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type DeleteProductDraftMutationFn = Apollo.MutationFunction<
  Types.DeleteProductDraftMutation,
  Types.DeleteProductDraftMutationVariables
>

/**
 * __useDeleteProductDraftMutation__
 *
 * To run a mutation, you first call `useDeleteProductDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductDraftMutation, { data, loading, error }] = useDeleteProductDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.DeleteProductDraftMutation, Types.DeleteProductDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteProductDraftMutation, Types.DeleteProductDraftMutationVariables>(
    DeleteProductDraftDocument,
    options
  )
}
export type DeleteProductDraftMutationHookResult = ReturnType<typeof useDeleteProductDraftMutation>
export type DeleteProductDraftMutationResult = Apollo.MutationResult<Types.DeleteProductDraftMutation>
export type DeleteProductDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteProductDraftMutation,
  Types.DeleteProductDraftMutationVariables
>
export const DeleteProductDraftVariantDocument = gql`
  mutation deleteProductDraftVariant($id: ID!) {
    deleteProductDraftVariant(id: $id) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type DeleteProductDraftVariantMutationFn = Apollo.MutationFunction<
  Types.DeleteProductDraftVariantMutation,
  Types.DeleteProductDraftVariantMutationVariables
>

/**
 * __useDeleteProductDraftVariantMutation__
 *
 * To run a mutation, you first call `useDeleteProductDraftVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductDraftVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductDraftVariantMutation, { data, loading, error }] = useDeleteProductDraftVariantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductDraftVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteProductDraftVariantMutation,
    Types.DeleteProductDraftVariantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.DeleteProductDraftVariantMutation, Types.DeleteProductDraftVariantMutationVariables>(
    DeleteProductDraftVariantDocument,
    options
  )
}
export type DeleteProductDraftVariantMutationHookResult = ReturnType<typeof useDeleteProductDraftVariantMutation>
export type DeleteProductDraftVariantMutationResult = Apollo.MutationResult<Types.DeleteProductDraftVariantMutation>
export type DeleteProductDraftVariantMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteProductDraftVariantMutation,
  Types.DeleteProductDraftVariantMutationVariables
>
export const UpdateInventoryItemIntegrationMetaDocument = gql`
  mutation UpdateInventoryItemIntegrationMeta($id: ID!, $integrationCode: IntegrationCode!, $metadata: JSONString!) {
    updateInventoryItemIntegrationMeta(id: $id, integrationCode: $integrationCode, metadata: $metadata) {
      inventoryItem {
        id
        integrationMeta(integrations: [$integrationCode]) {
          metadata
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type UpdateInventoryItemIntegrationMetaMutationFn = Apollo.MutationFunction<
  Types.UpdateInventoryItemIntegrationMetaMutation,
  Types.UpdateInventoryItemIntegrationMetaMutationVariables
>

/**
 * __useUpdateInventoryItemIntegrationMetaMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryItemIntegrationMetaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryItemIntegrationMetaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryItemIntegrationMetaMutation, { data, loading, error }] = useUpdateInventoryItemIntegrationMetaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      integrationCode: // value for 'integrationCode'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateInventoryItemIntegrationMetaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateInventoryItemIntegrationMetaMutation,
    Types.UpdateInventoryItemIntegrationMetaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateInventoryItemIntegrationMetaMutation,
    Types.UpdateInventoryItemIntegrationMetaMutationVariables
  >(UpdateInventoryItemIntegrationMetaDocument, options)
}
export type UpdateInventoryItemIntegrationMetaMutationHookResult = ReturnType<
  typeof useUpdateInventoryItemIntegrationMetaMutation
>
export type UpdateInventoryItemIntegrationMetaMutationResult =
  Apollo.MutationResult<Types.UpdateInventoryItemIntegrationMetaMutation>
export type UpdateInventoryItemIntegrationMetaMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateInventoryItemIntegrationMetaMutation,
  Types.UpdateInventoryItemIntegrationMetaMutationVariables
>
export const CreateProductDraftDocument = gql`
  mutation createProductDraft($input: ProductDraftCreateInput!) {
    createProductDraft(input: $input) {
      productDraft {
        id
        name
        variants {
          inventoryItem {
            id
          }
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type CreateProductDraftMutationFn = Apollo.MutationFunction<
  Types.CreateProductDraftMutation,
  Types.CreateProductDraftMutationVariables
>

/**
 * __useCreateProductDraftMutation__
 *
 * To run a mutation, you first call `useCreateProductDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductDraftMutation, { data, loading, error }] = useCreateProductDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateProductDraftMutation, Types.CreateProductDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.CreateProductDraftMutation, Types.CreateProductDraftMutationVariables>(
    CreateProductDraftDocument,
    options
  )
}
export type CreateProductDraftMutationHookResult = ReturnType<typeof useCreateProductDraftMutation>
export type CreateProductDraftMutationResult = Apollo.MutationResult<Types.CreateProductDraftMutation>
export type CreateProductDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateProductDraftMutation,
  Types.CreateProductDraftMutationVariables
>
export const UpdateProductDraftDocument = gql`
  mutation updateProductDraft($id: ID!, $input: ProductDraftUpdateInput!) {
    updateProductDraft(id: $id, input: $input) {
      productDraft {
        id
        name
        slug
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type UpdateProductDraftMutationFn = Apollo.MutationFunction<
  Types.UpdateProductDraftMutation,
  Types.UpdateProductDraftMutationVariables
>

/**
 * __useUpdateProductDraftMutation__
 *
 * To run a mutation, you first call `useUpdateProductDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDraftMutation, { data, loading, error }] = useUpdateProductDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateProductDraftMutation, Types.UpdateProductDraftMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdateProductDraftMutation, Types.UpdateProductDraftMutationVariables>(
    UpdateProductDraftDocument,
    options
  )
}
export type UpdateProductDraftMutationHookResult = ReturnType<typeof useUpdateProductDraftMutation>
export type UpdateProductDraftMutationResult = Apollo.MutationResult<Types.UpdateProductDraftMutation>
export type UpdateProductDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateProductDraftMutation,
  Types.UpdateProductDraftMutationVariables
>
export const UpdateSendcloudConfigurationDocument = gql`
  mutation UpdateSendcloudConfiguration($input: SendcloudConfigurationUpdateInput!) {
    updateSendcloudConfiguration(input: $input) {
      sendcloudConfiguration {
        id
        publicKey
        secretKey
        integration {
          ...IntegrationFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`
export type UpdateSendcloudConfigurationMutationFn = Apollo.MutationFunction<
  Types.UpdateSendcloudConfigurationMutation,
  Types.UpdateSendcloudConfigurationMutationVariables
>

/**
 * __useUpdateSendcloudConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateSendcloudConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendcloudConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendcloudConfigurationMutation, { data, loading, error }] = useUpdateSendcloudConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSendcloudConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateSendcloudConfigurationMutation,
    Types.UpdateSendcloudConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateSendcloudConfigurationMutation,
    Types.UpdateSendcloudConfigurationMutationVariables
  >(UpdateSendcloudConfigurationDocument, options)
}
export type UpdateSendcloudConfigurationMutationHookResult = ReturnType<typeof useUpdateSendcloudConfigurationMutation>
export type UpdateSendcloudConfigurationMutationResult =
  Apollo.MutationResult<Types.UpdateSendcloudConfigurationMutation>
export type UpdateSendcloudConfigurationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSendcloudConfigurationMutation,
  Types.UpdateSendcloudConfigurationMutationVariables
>
export const UpdateShipStationConfigurationDocument = gql`
  mutation UpdateShipStationConfiguration($input: ShipStationConfigurationUpdateInput!) {
    updateShipstationConfiguration(input: $input) {
      shipstationConfiguration {
        id
        apiKey
        apiSecret
        integration {
          ...IntegrationFragment
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
  ${ErrorFragmentFragmentDoc}
`
export type UpdateShipStationConfigurationMutationFn = Apollo.MutationFunction<
  Types.UpdateShipStationConfigurationMutation,
  Types.UpdateShipStationConfigurationMutationVariables
>

/**
 * __useUpdateShipStationConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateShipStationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipStationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipStationConfigurationMutation, { data, loading, error }] = useUpdateShipStationConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipStationConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateShipStationConfigurationMutation,
    Types.UpdateShipStationConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    Types.UpdateShipStationConfigurationMutation,
    Types.UpdateShipStationConfigurationMutationVariables
  >(UpdateShipStationConfigurationDocument, options)
}
export type UpdateShipStationConfigurationMutationHookResult = ReturnType<
  typeof useUpdateShipStationConfigurationMutation
>
export type UpdateShipStationConfigurationMutationResult =
  Apollo.MutationResult<Types.UpdateShipStationConfigurationMutation>
export type UpdateShipStationConfigurationMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateShipStationConfigurationMutation,
  Types.UpdateShipStationConfigurationMutationVariables
>
export const UpdateInventoryItemDocument = gql`
  mutation updateInventoryItem($id: ID!, $input: InventoryItemInput!) {
    updateInventoryItem(id: $id, input: $input) {
      inventoryItem {
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type UpdateInventoryItemMutationFn = Apollo.MutationFunction<
  Types.UpdateInventoryItemMutation,
  Types.UpdateInventoryItemMutationVariables
>

/**
 * __useUpdateInventoryItemMutation__
 *
 * To run a mutation, you first call `useUpdateInventoryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInventoryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInventoryItemMutation, { data, loading, error }] = useUpdateInventoryItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInventoryItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateInventoryItemMutation,
    Types.UpdateInventoryItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdateInventoryItemMutation, Types.UpdateInventoryItemMutationVariables>(
    UpdateInventoryItemDocument,
    options
  )
}
export type UpdateInventoryItemMutationHookResult = ReturnType<typeof useUpdateInventoryItemMutation>
export type UpdateInventoryItemMutationResult = Apollo.MutationResult<Types.UpdateInventoryItemMutation>
export type UpdateInventoryItemMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateInventoryItemMutation,
  Types.UpdateInventoryItemMutationVariables
>
export const UpdateProductDraftVariantDocument = gql`
  mutation updateProductDraftVariant($id: ID!, $input: ProductDraftVariantUpdateInput!) {
    updateProductDraftVariant(id: $id, input: $input) {
      productDraftVariant {
        id
        name
        slug
        productDraft {
          id
        }
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type UpdateProductDraftVariantMutationFn = Apollo.MutationFunction<
  Types.UpdateProductDraftVariantMutation,
  Types.UpdateProductDraftVariantMutationVariables
>

/**
 * __useUpdateProductDraftVariantMutation__
 *
 * To run a mutation, you first call `useUpdateProductDraftVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDraftVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDraftVariantMutation, { data, loading, error }] = useUpdateProductDraftVariantMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductDraftVariantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateProductDraftVariantMutation,
    Types.UpdateProductDraftVariantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UpdateProductDraftVariantMutation, Types.UpdateProductDraftVariantMutationVariables>(
    UpdateProductDraftVariantDocument,
    options
  )
}
export type UpdateProductDraftVariantMutationHookResult = ReturnType<typeof useUpdateProductDraftVariantMutation>
export type UpdateProductDraftVariantMutationResult = Apollo.MutationResult<Types.UpdateProductDraftVariantMutation>
export type UpdateProductDraftVariantMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateProductDraftVariantMutation,
  Types.UpdateProductDraftVariantMutationVariables
>
export const UploadProductImageDocument = gql`
  mutation uploadProductImage($image: Upload!) {
    uploadProductImage(image: $image) {
      productImage {
        url
        id
      }
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ErrorFragmentFragmentDoc}
`
export type UploadProductImageMutationFn = Apollo.MutationFunction<
  Types.UploadProductImageMutation,
  Types.UploadProductImageMutationVariables
>

/**
 * __useUploadProductImageMutation__
 *
 * To run a mutation, you first call `useUploadProductImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductImageMutation, { data, loading, error }] = useUploadProductImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadProductImageMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UploadProductImageMutation, Types.UploadProductImageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<Types.UploadProductImageMutation, Types.UploadProductImageMutationVariables>(
    UploadProductImageDocument,
    options
  )
}
export type UploadProductImageMutationHookResult = ReturnType<typeof useUploadProductImageMutation>
export type UploadProductImageMutationResult = Apollo.MutationResult<Types.UploadProductImageMutation>
export type UploadProductImageMutationOptions = Apollo.BaseMutationOptions<
  Types.UploadProductImageMutation,
  Types.UploadProductImageMutationVariables
>
export const AllCategoriesDocument = gql`
  query allCategories(
    $filter: CategoryFilterInput
    $sortBy: CategorySortingInput
    $language: Language!
    $parentId: ID
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    categories(
      language: $language
      filter: $filter
      sortBy: $sortBy
      parentId: $parentId
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          name
          path
          subcategories {
            id
            name
            path
            subcategories {
              id
              name
              path
            }
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useAllCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      language: // value for 'language'
 *      parentId: // value for 'parentId'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAllCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>(AllCategoriesDocument, options)
}
export function useAllCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>(
    AllCategoriesDocument,
    options
  )
}
export type AllCategoriesQueryHookResult = ReturnType<typeof useAllCategoriesQuery>
export type AllCategoriesLazyQueryHookResult = ReturnType<typeof useAllCategoriesLazyQuery>
export type AllCategoriesQueryResult = Apollo.QueryResult<Types.AllCategoriesQuery, Types.AllCategoriesQueryVariables>
export const BaselinkerConfigurationDocument = gql`
  query BaselinkerConfiguration {
    baselinkerConfiguration {
      id
      apiKey
      newOrderStatusId
      integration {
        ...IntegrationFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
`

/**
 * __useBaselinkerConfigurationQuery__
 *
 * To run a query within a React component, call `useBaselinkerConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaselinkerConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaselinkerConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useBaselinkerConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.BaselinkerConfigurationQuery, Types.BaselinkerConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.BaselinkerConfigurationQuery, Types.BaselinkerConfigurationQueryVariables>(
    BaselinkerConfigurationDocument,
    options
  )
}
export function useBaselinkerConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.BaselinkerConfigurationQuery,
    Types.BaselinkerConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.BaselinkerConfigurationQuery, Types.BaselinkerConfigurationQueryVariables>(
    BaselinkerConfigurationDocument,
    options
  )
}
export type BaselinkerConfigurationQueryHookResult = ReturnType<typeof useBaselinkerConfigurationQuery>
export type BaselinkerConfigurationLazyQueryHookResult = ReturnType<typeof useBaselinkerConfigurationLazyQuery>
export type BaselinkerConfigurationQueryResult = Apollo.QueryResult<
  Types.BaselinkerConfigurationQuery,
  Types.BaselinkerConfigurationQueryVariables
>
export const BriefInventoryLocationsDocument = gql`
  query briefInventoryLocations(
    $filter: InventoryLocationFilterInput
    $sortBy: InventoryLocationSortingInput
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    inventoryLocations(filter: $filter, sortBy: $sortBy, before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useBriefInventoryLocationsQuery__
 *
 * To run a query within a React component, call `useBriefInventoryLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBriefInventoryLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBriefInventoryLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBriefInventoryLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.BriefInventoryLocationsQuery, Types.BriefInventoryLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.BriefInventoryLocationsQuery, Types.BriefInventoryLocationsQueryVariables>(
    BriefInventoryLocationsDocument,
    options
  )
}
export function useBriefInventoryLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.BriefInventoryLocationsQuery,
    Types.BriefInventoryLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.BriefInventoryLocationsQuery, Types.BriefInventoryLocationsQueryVariables>(
    BriefInventoryLocationsDocument,
    options
  )
}
export type BriefInventoryLocationsQueryHookResult = ReturnType<typeof useBriefInventoryLocationsQuery>
export type BriefInventoryLocationsLazyQueryHookResult = ReturnType<typeof useBriefInventoryLocationsLazyQuery>
export type BriefInventoryLocationsQueryResult = Apollo.QueryResult<
  Types.BriefInventoryLocationsQuery,
  Types.BriefInventoryLocationsQueryVariables
>
export const CategoriesDocument = gql`
  query categories(
    $filter: CategoryFilterInput
    $sortBy: CategorySortingInput
    $language: Language!
    $parentId: ID
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    categories(
      language: $language
      filter: $filter
      sortBy: $sortBy
      parentId: $parentId
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...CategoryFragment
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${CategoryFragmentFragmentDoc}
`

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      language: // value for 'language'
 *      parentId: // value for 'parentId'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CategoriesQuery, Types.CategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CategoriesQuery, Types.CategoriesQueryVariables>(CategoriesDocument, options)
}
export function useCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CategoriesQuery, Types.CategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CategoriesQuery, Types.CategoriesQueryVariables>(CategoriesDocument, options)
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>
export type CategoriesQueryResult = Apollo.QueryResult<Types.CategoriesQuery, Types.CategoriesQueryVariables>
export const CropsterConfigurationDocument = gql`
  query CropsterConfiguration {
    cropsterConfiguration {
      id
      integration {
        ...IntegrationFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
`

/**
 * __useCropsterConfigurationQuery__
 *
 * To run a query within a React component, call `useCropsterConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCropsterConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCropsterConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCropsterConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.CropsterConfigurationQuery, Types.CropsterConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CropsterConfigurationQuery, Types.CropsterConfigurationQueryVariables>(
    CropsterConfigurationDocument,
    options
  )
}
export function useCropsterConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CropsterConfigurationQuery, Types.CropsterConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CropsterConfigurationQuery, Types.CropsterConfigurationQueryVariables>(
    CropsterConfigurationDocument,
    options
  )
}
export type CropsterConfigurationQueryHookResult = ReturnType<typeof useCropsterConfigurationQuery>
export type CropsterConfigurationLazyQueryHookResult = ReturnType<typeof useCropsterConfigurationLazyQuery>
export type CropsterConfigurationQueryResult = Apollo.QueryResult<
  Types.CropsterConfigurationQuery,
  Types.CropsterConfigurationQueryVariables
>
export const CropsterOrderableProductDocument = gql`
  query CropsterOrderableProduct {
    cropsterConfiguration {
      id
      apiKey
      apiSecret
      orderableProducts {
        ...CropsterOrderableProductsFragment
      }
      integration {
        ...IntegrationFragment
      }
    }
  }
  ${CropsterOrderableProductsFragmentFragmentDoc}
  ${IntegrationFragmentFragmentDoc}
`

/**
 * __useCropsterOrderableProductQuery__
 *
 * To run a query within a React component, call `useCropsterOrderableProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useCropsterOrderableProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCropsterOrderableProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useCropsterOrderableProductQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CropsterOrderableProductQuery,
    Types.CropsterOrderableProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CropsterOrderableProductQuery, Types.CropsterOrderableProductQueryVariables>(
    CropsterOrderableProductDocument,
    options
  )
}
export function useCropsterOrderableProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CropsterOrderableProductQuery,
    Types.CropsterOrderableProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CropsterOrderableProductQuery, Types.CropsterOrderableProductQueryVariables>(
    CropsterOrderableProductDocument,
    options
  )
}
export type CropsterOrderableProductQueryHookResult = ReturnType<typeof useCropsterOrderableProductQuery>
export type CropsterOrderableProductLazyQueryHookResult = ReturnType<typeof useCropsterOrderableProductLazyQuery>
export type CropsterOrderableProductQueryResult = Apollo.QueryResult<
  Types.CropsterOrderableProductQuery,
  Types.CropsterOrderableProductQueryVariables
>
export const CustomerOrderDocument = gql`
  query customerOrder(
    $filter: CustomerOrderFilterInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: OrderSortingInput
  ) {
    customerOrders(filter: $filter, before: $before, after: $after, first: $first, last: $last, sortBy: $sortBy) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          id
          number
          title
          status
          total {
            ...TaxedMoneyFragment
          }
          createdAt
          updatedAt
          fulfillmentStatus
          paymentStatus
          notes
          shippingMethod
          deliveryDate
          fulfillmentStatus
          store {
            id
            name
            legalName
            logo {
              url
            }
          }
          customer {
            email
            firstName
            lastName
            fullName
            phone
          }
          shippingAddress {
            street
            houseNumber
            city
            zipCode
            name
            country
            companyName
            email
            phone
          }
          billingAddress {
            street
            houseNumber
            city
            zipCode
            name
            country
            companyName
            taxId
          }
          paymentMethod {
            id
            payer {
              id
              user {
                email
                firstName
                lastName
                fullName
                phone
              }
            }
            gateway
            paymentType
          }
          payments {
            id
            paymentMethod {
              id
              payer {
                id
                user {
                  email
                  firstName
                  lastName
                  fullName
                  phone
                }
              }
              gateway
              paymentType
            }
            payer {
              id
              user {
                email
                firstName
                lastName
                fullName
                phone
              }
            }
            total {
              ...MoneyFragment
            }
            pspReference
            abandoned
            status
            gateway
            capturedAmount {
              ...MoneyFragment
            }
            createdAt
            updatedAt
            transactions {
              id
              token
              kind
              isSuccess
              total {
                ...MoneyFragment
              }
            }
          }
          activities {
            id
            event
            actor {
              fullName
              email
              phone
            }
            createdAt
            updatedAt
            customerMetadata(keys: ["amount", "currency", "gateway", "payment_id", "payment_type", "invoice_id"])
          }
          invoices {
            id
            number
            pdfFile {
              url
            }
            createdAt
            effectiveDate
            total {
              ...MoneyFragment
            }
          }
          items {
            id
            productName
            sku
            total {
              ...TaxedMoneyFragment
            }
            quantity
            type
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
`

/**
 * __useCustomerOrderQuery__
 *
 * To run a query within a React component, call `useCustomerOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerOrderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useCustomerOrderQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.CustomerOrderQuery, Types.CustomerOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CustomerOrderQuery, Types.CustomerOrderQueryVariables>(CustomerOrderDocument, options)
}
export function useCustomerOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomerOrderQuery, Types.CustomerOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CustomerOrderQuery, Types.CustomerOrderQueryVariables>(
    CustomerOrderDocument,
    options
  )
}
export type CustomerOrderQueryHookResult = ReturnType<typeof useCustomerOrderQuery>
export type CustomerOrderLazyQueryHookResult = ReturnType<typeof useCustomerOrderLazyQuery>
export type CustomerOrderQueryResult = Apollo.QueryResult<Types.CustomerOrderQuery, Types.CustomerOrderQueryVariables>
export const CustomersListDocument = gql`
  query customersList(
    $filter: CustomerFilterInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: CustomerSortingInput
  ) {
    customers(filter: $filter, before: $before, after: $after, first: $first, last: $last, sortBy: $sortBy) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      totalPerStatus
      edges {
        node {
          id
          status
          lastOrderTime
          createdAt
          name
          user {
            email
            firstName
            lastName
            fullName
          }
          invite {
            firstName
            lastName
            email
          }
          store {
            id
            name
          }
          ordersSum {
            amount
            currency
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useCustomersListQuery__
 *
 * To run a query within a React component, call `useCustomersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useCustomersListQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.CustomersListQuery, Types.CustomersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.CustomersListQuery, Types.CustomersListQueryVariables>(CustomersListDocument, options)
}
export function useCustomersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CustomersListQuery, Types.CustomersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.CustomersListQuery, Types.CustomersListQueryVariables>(
    CustomersListDocument,
    options
  )
}
export type CustomersListQueryHookResult = ReturnType<typeof useCustomersListQuery>
export type CustomersListLazyQueryHookResult = ReturnType<typeof useCustomersListLazyQuery>
export type CustomersListQueryResult = Apollo.QueryResult<Types.CustomersListQuery, Types.CustomersListQueryVariables>
export const FlavourProfilesDocument = gql`
  query flavourProfiles($language: Language) {
    flavourProfiles(language: $language) {
      edges {
        node {
          id
          name
          image {
            url
          }
        }
      }
    }
  }
`

/**
 * __useFlavourProfilesQuery__
 *
 * To run a query within a React component, call `useFlavourProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlavourProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlavourProfilesQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useFlavourProfilesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.FlavourProfilesQuery, Types.FlavourProfilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.FlavourProfilesQuery, Types.FlavourProfilesQueryVariables>(
    FlavourProfilesDocument,
    options
  )
}
export function useFlavourProfilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.FlavourProfilesQuery, Types.FlavourProfilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.FlavourProfilesQuery, Types.FlavourProfilesQueryVariables>(
    FlavourProfilesDocument,
    options
  )
}
export type FlavourProfilesQueryHookResult = ReturnType<typeof useFlavourProfilesQuery>
export type FlavourProfilesLazyQueryHookResult = ReturnType<typeof useFlavourProfilesLazyQuery>
export type FlavourProfilesQueryResult = Apollo.QueryResult<
  Types.FlavourProfilesQuery,
  Types.FlavourProfilesQueryVariables
>
export const InventoryItemsDocument = gql`
  query InventoryItems(
    $filter: InventoryItemFilterInput
    $sortBy: InventoryItemSortingInput
    $language: Language!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    inventoryItems(
      first: $first
      after: $after
      filter: $filter
      sortBy: $sortBy
      language: $language
      before: $before
      last: $last
    ) {
      edges {
        cursor
        node {
          id
          sku
          price {
            ...TaxedMoneyFragment
          }
          taxGroup {
            ...TaxGroupFragment
          }
          product(language: $language) {
            name
            id
            manufacturer {
              id
              name
            }
            weight {
              unit
              value
            }
            categories {
              id
              slug
              name
            }
            bodyHtml
          }
          productDraftVariant(language: $language) {
            id
            name
            slug
            description
            inventoryItem {
              id
            }
            weight {
              unit
              value
            }
            images {
              url
              id
            }
            productDraft {
              id
              family {
                id
                name
              }
              manufacturer {
                id
                name
              }
              name
              slug
              description
              weight {
                unit
                value
              }
              images {
                url
                id
              }
              categories {
                id
                slug
                name
              }
              variants {
                id
                name
                slug
                inventoryItem {
                  id
                  sku
                }
                images {
                  url
                  id
                }
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${TaxedMoneyFragmentFragmentDoc}
  ${TaxGroupFragmentFragmentDoc}
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useInventoryItemsQuery__
 *
 * To run a query within a React component, call `useInventoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useInventoryItemsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.InventoryItemsQuery, Types.InventoryItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.InventoryItemsQuery, Types.InventoryItemsQueryVariables>(InventoryItemsDocument, options)
}
export function useInventoryItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.InventoryItemsQuery, Types.InventoryItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.InventoryItemsQuery, Types.InventoryItemsQueryVariables>(
    InventoryItemsDocument,
    options
  )
}
export type InventoryItemsQueryHookResult = ReturnType<typeof useInventoryItemsQuery>
export type InventoryItemsLazyQueryHookResult = ReturnType<typeof useInventoryItemsLazyQuery>
export type InventoryItemsQueryResult = Apollo.QueryResult<
  Types.InventoryItemsQuery,
  Types.InventoryItemsQueryVariables
>
export const InventoryItemsWithIntegrationsDocument = gql`
  query inventoryItemsWithIntegrations(
    $first: Int!
    $after: String
    $filter: InventoryItemFilterInput
    $integrations: [IntegrationCode]
    $language: Language!
  ) {
    inventoryItems(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          sku
          integrationMeta(integrations: $integrations) {
            metadata
          }
          product(language: $language) {
            ...ProductFragment
          }
        }
      }
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${ProductFragmentFragmentDoc}
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useInventoryItemsWithIntegrationsQuery__
 *
 * To run a query within a React component, call `useInventoryItemsWithIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemsWithIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemsWithIntegrationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      integrations: // value for 'integrations'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useInventoryItemsWithIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.InventoryItemsWithIntegrationsQuery,
    Types.InventoryItemsWithIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.InventoryItemsWithIntegrationsQuery, Types.InventoryItemsWithIntegrationsQueryVariables>(
    InventoryItemsWithIntegrationsDocument,
    options
  )
}
export function useInventoryItemsWithIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.InventoryItemsWithIntegrationsQuery,
    Types.InventoryItemsWithIntegrationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    Types.InventoryItemsWithIntegrationsQuery,
    Types.InventoryItemsWithIntegrationsQueryVariables
  >(InventoryItemsWithIntegrationsDocument, options)
}
export type InventoryItemsWithIntegrationsQueryHookResult = ReturnType<typeof useInventoryItemsWithIntegrationsQuery>
export type InventoryItemsWithIntegrationsLazyQueryHookResult = ReturnType<
  typeof useInventoryItemsWithIntegrationsLazyQuery
>
export type InventoryItemsWithIntegrationsQueryResult = Apollo.QueryResult<
  Types.InventoryItemsWithIntegrationsQuery,
  Types.InventoryItemsWithIntegrationsQueryVariables
>
export const InventoryItemsPreviewDocument = gql`
  query inventoryItemsPreview(
    $first: Int!
    $after: String
    $filter: InventoryItemFilterInput
    $language: Language!
    $locationId: ID!
    $sortBy: InventoryItemSortingInput
  ) {
    inventoryItems(first: $first, after: $after, filter: $filter, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          sku
          price {
            ...TaxedMoneyFragment
          }
          product(language: $language) {
            id
            name
            slug
            media {
              ...MediaFragment
            }
            categories {
              id
              name
              slug
            }
          }
          productDraftVariant(language: $language) {
            id
            name
            slug
            images {
              id
              url
            }
            productDraft {
              id
              name
              slug
              categories {
                id
                name
                slug
              }
              images {
                id
                url
              }
              variants {
                id
              }
            }
          }
          quantity(locationId: $locationId)
        }
      }
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${TaxedMoneyFragmentFragmentDoc}
  ${MediaFragmentFragmentDoc}
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useInventoryItemsPreviewQuery__
 *
 * To run a query within a React component, call `useInventoryItemsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryItemsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryItemsPreviewQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *      locationId: // value for 'locationId'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useInventoryItemsPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<Types.InventoryItemsPreviewQuery, Types.InventoryItemsPreviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.InventoryItemsPreviewQuery, Types.InventoryItemsPreviewQueryVariables>(
    InventoryItemsPreviewDocument,
    options
  )
}
export function useInventoryItemsPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.InventoryItemsPreviewQuery, Types.InventoryItemsPreviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.InventoryItemsPreviewQuery, Types.InventoryItemsPreviewQueryVariables>(
    InventoryItemsPreviewDocument,
    options
  )
}
export type InventoryItemsPreviewQueryHookResult = ReturnType<typeof useInventoryItemsPreviewQuery>
export type InventoryItemsPreviewLazyQueryHookResult = ReturnType<typeof useInventoryItemsPreviewLazyQuery>
export type InventoryItemsPreviewQueryResult = Apollo.QueryResult<
  Types.InventoryItemsPreviewQuery,
  Types.InventoryItemsPreviewQueryVariables
>
export const InventoryLocationsDocument = gql`
  query inventoryLocations(
    $filter: InventoryLocationFilterInput
    $sortBy: InventoryLocationSortingInput
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    inventoryLocations(filter: $filter, sortBy: $sortBy, before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        cursor
        node {
          id
          code
          name
          fulfillmentTime {
            id
            value
            fulfillmentTimeType
          }
          availabilityType
          createdAt
          updatedAt
        }
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useInventoryLocationsQuery__
 *
 * To run a query within a React component, call `useInventoryLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useInventoryLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.InventoryLocationsQuery, Types.InventoryLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.InventoryLocationsQuery, Types.InventoryLocationsQueryVariables>(
    InventoryLocationsDocument,
    options
  )
}
export function useInventoryLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.InventoryLocationsQuery, Types.InventoryLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.InventoryLocationsQuery, Types.InventoryLocationsQueryVariables>(
    InventoryLocationsDocument,
    options
  )
}
export type InventoryLocationsQueryHookResult = ReturnType<typeof useInventoryLocationsQuery>
export type InventoryLocationsLazyQueryHookResult = ReturnType<typeof useInventoryLocationsLazyQuery>
export type InventoryLocationsQueryResult = Apollo.QueryResult<
  Types.InventoryLocationsQuery,
  Types.InventoryLocationsQueryVariables
>
export const LoginPageDocument = gql`
  query loginPage($endpointSlug: String!, $pageType: PageType) {
    page(endpointSlug: $endpointSlug, pageType: $pageType) {
      endpointSlug
      storeSlug
      images {
        customIdentifier
        imagePurpose
        image {
          url
        }
      }
      texts {
        text
        customIdentifier
      }
    }
  }
`

/**
 * __useLoginPageQuery__
 *
 * To run a query within a React component, call `useLoginPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginPageQuery({
 *   variables: {
 *      endpointSlug: // value for 'endpointSlug'
 *      pageType: // value for 'pageType'
 *   },
 * });
 */
export function useLoginPageQuery(
  baseOptions: Apollo.QueryHookOptions<Types.LoginPageQuery, Types.LoginPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.LoginPageQuery, Types.LoginPageQueryVariables>(LoginPageDocument, options)
}
export function useLoginPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LoginPageQuery, Types.LoginPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.LoginPageQuery, Types.LoginPageQueryVariables>(LoginPageDocument, options)
}
export type LoginPageQueryHookResult = ReturnType<typeof useLoginPageQuery>
export type LoginPageLazyQueryHookResult = ReturnType<typeof useLoginPageLazyQuery>
export type LoginPageQueryResult = Apollo.QueryResult<Types.LoginPageQuery, Types.LoginPageQueryVariables>
export const ManufacturersDocument = gql`
  query manufacturers(
    $filter: ManufacturerFilterInput
    $sortBy: ManufacturerSortingInput
    $language: Language
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    manufacturers(
      language: $language
      filter: $filter
      sortBy: $sortBy
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...ManufacturerFragment
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${ManufacturerFragmentFragmentDoc}
`

/**
 * __useManufacturersQuery__
 *
 * To run a query within a React component, call `useManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useManufacturersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>(ManufacturersDocument, options)
}
export function useManufacturersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>(
    ManufacturersDocument,
    options
  )
}
export type ManufacturersQueryHookResult = ReturnType<typeof useManufacturersQuery>
export type ManufacturersLazyQueryHookResult = ReturnType<typeof useManufacturersLazyQuery>
export type ManufacturersQueryResult = Apollo.QueryResult<Types.ManufacturersQuery, Types.ManufacturersQueryVariables>
export const NavigationStoresDocument = gql`
  query navigationStores($before: String, $after: String, $first: Int, $last: Int, $filter: StoreFilterInput) {
    stores(before: $before, after: $after, first: $first, last: $last, filter: $filter) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          ...NavigationStoreFragment
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${NavigationStoreFragmentFragmentDoc}
`

/**
 * __useNavigationStoresQuery__
 *
 * To run a query within a React component, call `useNavigationStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationStoresQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNavigationStoresQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.NavigationStoresQuery, Types.NavigationStoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.NavigationStoresQuery, Types.NavigationStoresQueryVariables>(
    NavigationStoresDocument,
    options
  )
}
export function useNavigationStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.NavigationStoresQuery, Types.NavigationStoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.NavigationStoresQuery, Types.NavigationStoresQueryVariables>(
    NavigationStoresDocument,
    options
  )
}
export type NavigationStoresQueryHookResult = ReturnType<typeof useNavigationStoresQuery>
export type NavigationStoresLazyQueryHookResult = ReturnType<typeof useNavigationStoresLazyQuery>
export type NavigationStoresQueryResult = Apollo.QueryResult<
  Types.NavigationStoresQuery,
  Types.NavigationStoresQueryVariables
>
export const OwnStoresDocument = gql`
  query ownStores($before: String, $after: String, $first: Int, $last: Int) {
    ownStores(before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          ...NavigationStoreFragment
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${NavigationStoreFragmentFragmentDoc}
`

/**
 * __useOwnStoresQuery__
 *
 * To run a query within a React component, call `useOwnStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnStoresQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useOwnStoresQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.OwnStoresQuery, Types.OwnStoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.OwnStoresQuery, Types.OwnStoresQueryVariables>(OwnStoresDocument, options)
}
export function useOwnStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.OwnStoresQuery, Types.OwnStoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.OwnStoresQuery, Types.OwnStoresQueryVariables>(OwnStoresDocument, options)
}
export type OwnStoresQueryHookResult = ReturnType<typeof useOwnStoresQuery>
export type OwnStoresLazyQueryHookResult = ReturnType<typeof useOwnStoresLazyQuery>
export type OwnStoresQueryResult = Apollo.QueryResult<Types.OwnStoresQuery, Types.OwnStoresQueryVariables>
export const PreviewCustomerOrdersDocument = gql`
  query previewCustomerOrders(
    $filter: CustomerOrderFilterInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: OrderSortingInput
  ) {
    customerOrders(filter: $filter, before: $before, after: $after, first: $first, last: $last, sortBy: $sortBy) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          id
          number
          status
          total {
            ...TaxedMoneyFragment
          }
          createdAt
          fulfillmentStatus
          paymentStatus
          store {
            id
            name
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
`

/**
 * __usePreviewCustomerOrdersQuery__
 *
 * To run a query within a React component, call `usePreviewCustomerOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewCustomerOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewCustomerOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePreviewCustomerOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PreviewCustomerOrdersQuery, Types.PreviewCustomerOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.PreviewCustomerOrdersQuery, Types.PreviewCustomerOrdersQueryVariables>(
    PreviewCustomerOrdersDocument,
    options
  )
}
export function usePreviewCustomerOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PreviewCustomerOrdersQuery, Types.PreviewCustomerOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.PreviewCustomerOrdersQuery, Types.PreviewCustomerOrdersQueryVariables>(
    PreviewCustomerOrdersDocument,
    options
  )
}
export type PreviewCustomerOrdersQueryHookResult = ReturnType<typeof usePreviewCustomerOrdersQuery>
export type PreviewCustomerOrdersLazyQueryHookResult = ReturnType<typeof usePreviewCustomerOrdersLazyQuery>
export type PreviewCustomerOrdersQueryResult = Apollo.QueryResult<
  Types.PreviewCustomerOrdersQuery,
  Types.PreviewCustomerOrdersQueryVariables
>
export const PreviewProductDraftsDocument = gql`
  query previewProductDrafts(
    $filter: ProductDraftFilterInput
    $language: Language
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: ProductDraftSortingInput
  ) {
    productDrafts(
      filter: $filter
      before: $before
      after: $after
      first: $first
      last: $last
      sortBy: $sortBy
      language: $language
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          id
          family {
            id
            name
            slug
          }
          name
          slug
          attributes {
            attribute {
              id
              slug
              name
              description
              isRequired
              isVariant
              dataType
              choices {
                id
                slug
                name
              }
              referenceType
            }
            value {
              id
              name
              value
            }
          }
          images {
            url
            id
          }
          variants {
            id
            name
            slug
            attributes {
              attribute {
                id
                slug
                name
                description
                isRequired
                isVariant
                dataType
                choices {
                  id
                  slug
                  name
                }
                referenceType
              }
              value {
                id
                name
                value
              }
            }
          }
          categories {
            id
            slug
            name
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __usePreviewProductDraftsQuery__
 *
 * To run a query within a React component, call `usePreviewProductDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewProductDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewProductDraftsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePreviewProductDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PreviewProductDraftsQuery, Types.PreviewProductDraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.PreviewProductDraftsQuery, Types.PreviewProductDraftsQueryVariables>(
    PreviewProductDraftsDocument,
    options
  )
}
export function usePreviewProductDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PreviewProductDraftsQuery, Types.PreviewProductDraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.PreviewProductDraftsQuery, Types.PreviewProductDraftsQueryVariables>(
    PreviewProductDraftsDocument,
    options
  )
}
export type PreviewProductDraftsQueryHookResult = ReturnType<typeof usePreviewProductDraftsQuery>
export type PreviewProductDraftsLazyQueryHookResult = ReturnType<typeof usePreviewProductDraftsLazyQuery>
export type PreviewProductDraftsQueryResult = Apollo.QueryResult<
  Types.PreviewProductDraftsQuery,
  Types.PreviewProductDraftsQueryVariables
>
export const PreviewStoreOrdersDocument = gql`
  query previewStoreOrders(
    $filter: VendorOrderFilterInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: OrderSortingInput
  ) {
    storeOrders(filter: $filter, before: $before, after: $after, first: $first, last: $last, sortBy: $sortBy) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          id
          number
          status
          total {
            ...TaxedMoneyFragment
          }
          createdAt
          paymentStatus
          fulfillmentStatus
          customer {
            fullName
          }
          store {
            id
            name
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
`

/**
 * __usePreviewStoreOrdersQuery__
 *
 * To run a query within a React component, call `usePreviewStoreOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewStoreOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewStoreOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePreviewStoreOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PreviewStoreOrdersQuery, Types.PreviewStoreOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.PreviewStoreOrdersQuery, Types.PreviewStoreOrdersQueryVariables>(
    PreviewStoreOrdersDocument,
    options
  )
}
export function usePreviewStoreOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PreviewStoreOrdersQuery, Types.PreviewStoreOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.PreviewStoreOrdersQuery, Types.PreviewStoreOrdersQueryVariables>(
    PreviewStoreOrdersDocument,
    options
  )
}
export type PreviewStoreOrdersQueryHookResult = ReturnType<typeof usePreviewStoreOrdersQuery>
export type PreviewStoreOrdersLazyQueryHookResult = ReturnType<typeof usePreviewStoreOrdersLazyQuery>
export type PreviewStoreOrdersQueryResult = Apollo.QueryResult<
  Types.PreviewStoreOrdersQuery,
  Types.PreviewStoreOrdersQueryVariables
>
export const PrivateProductsDocument = gql`
  query privateProducts(
    $filter: ProductFilterInput
    $language: Language!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $marketplaceOnly: Boolean
    $storeId: ID
  ) {
    privateProducts(
      filter: $filter
      language: $language
      before: $before
      after: $after
      first: $first
      last: $last
      storeId: $storeId
      marketplaceOnly: $marketplaceOnly
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          slug
          name
          bodyHtml
          manufacturer {
            ...ManufacturerFragment
          }
          offers {
            sku
            store {
              ...StoreFragment
            }
            catalogPrice {
              ...MoneyFragment
            }
            sellingPrice {
              ...TaxedMoneyFragment
            }
            discountRate
            taxRate
            quantity
            availability {
              ...ProductAvailabilityFragment
            }
            defaultShippingMethod {
              ...StoreShippingMethodFragment
            }
          }
          subscribed
          weight {
            ...WeightFragment
          }
          media {
            ...MediaFragment
          }
          relatedProducts {
            type
            variants {
              description
              current
              value
              product {
                id
                slug
                name
                offers {
                  quantity
                }
              }
            }
          }
          coffeeInfo {
            origins {
              country
              processingType
            }
            format
            roastProfile
            brewingProfile
          }
        }
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${ManufacturerFragmentFragmentDoc}
  ${StoreFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${ProductAvailabilityFragmentFragmentDoc}
  ${StoreShippingMethodFragmentFragmentDoc}
  ${WeightFragmentFragmentDoc}
  ${MediaFragmentFragmentDoc}
`

/**
 * __usePrivateProductsQuery__
 *
 * To run a query within a React component, call `usePrivateProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      marketplaceOnly: // value for 'marketplaceOnly'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function usePrivateProductsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.PrivateProductsQuery, Types.PrivateProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.PrivateProductsQuery, Types.PrivateProductsQueryVariables>(
    PrivateProductsDocument,
    options
  )
}
export function usePrivateProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PrivateProductsQuery, Types.PrivateProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.PrivateProductsQuery, Types.PrivateProductsQueryVariables>(
    PrivateProductsDocument,
    options
  )
}
export type PrivateProductsQueryHookResult = ReturnType<typeof usePrivateProductsQuery>
export type PrivateProductsLazyQueryHookResult = ReturnType<typeof usePrivateProductsLazyQuery>
export type PrivateProductsQueryResult = Apollo.QueryResult<
  Types.PrivateProductsQuery,
  Types.PrivateProductsQueryVariables
>
export const PrivateProductsListDocument = gql`
  query privateProductsList(
    $filter: ProductFilterInput
    $language: Language!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $marketplaceOnly: Boolean
    $storeId: ID
  ) {
    privateProducts(
      filter: $filter
      language: $language
      before: $before
      after: $after
      first: $first
      last: $last
      storeId: $storeId
      marketplaceOnly: $marketplaceOnly
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          slug
          name
          offers {
            catalogPrice {
              ...MoneyFragment
            }
            sellingPrice {
              ...TaxedMoneyFragment
            }
            taxRate
            discountRate
            quantity
            availability {
              ...ProductAvailabilityFragment
            }
          }
          media {
            ...MediaFragment
          }
        }
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${ProductAvailabilityFragmentFragmentDoc}
  ${MediaFragmentFragmentDoc}
`

/**
 * __usePrivateProductsListQuery__
 *
 * To run a query within a React component, call `usePrivateProductsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrivateProductsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrivateProductsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      marketplaceOnly: // value for 'marketplaceOnly'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function usePrivateProductsListQuery(
  baseOptions: Apollo.QueryHookOptions<Types.PrivateProductsListQuery, Types.PrivateProductsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.PrivateProductsListQuery, Types.PrivateProductsListQueryVariables>(
    PrivateProductsListDocument,
    options
  )
}
export function usePrivateProductsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PrivateProductsListQuery, Types.PrivateProductsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.PrivateProductsListQuery, Types.PrivateProductsListQueryVariables>(
    PrivateProductsListDocument,
    options
  )
}
export type PrivateProductsListQueryHookResult = ReturnType<typeof usePrivateProductsListQuery>
export type PrivateProductsListLazyQueryHookResult = ReturnType<typeof usePrivateProductsListLazyQuery>
export type PrivateProductsListQueryResult = Apollo.QueryResult<
  Types.PrivateProductsListQuery,
  Types.PrivateProductsListQueryVariables
>
export const ProductDraftsDocument = gql`
  query productDrafts(
    $filter: ProductDraftFilterInput
    $language: Language
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: ProductDraftSortingInput
  ) {
    productDrafts(
      filter: $filter
      before: $before
      after: $after
      first: $first
      last: $last
      sortBy: $sortBy
      language: $language
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          id
          family {
            id
            name
            slug
            attributes {
              id
              slug
              name
              description
              isRequired
              isVariant
              dataType
              choices {
                id
                slug
                name
              }
              referenceType
            }
          }
          manufacturer {
            id
            name
          }
          name
          slug
          description
          weight {
            ...WeightFragment
          }
          attributes {
            attribute {
              id
              slug
              name
              description
              isRequired
              isVariant
              dataType
              choices {
                id
                slug
                name
              }
              referenceType
            }
            value {
              id
              name
              value
            }
          }
          images {
            url
            id
          }
          variants {
            id
            name
            slug
            description
            inventoryItem {
              id
              sku
              createdAt
              updatedAt
              basePrice {
                ...MoneyFragment
              }
              price {
                ...TaxedMoneyFragment
              }
              taxGroup {
                ...TaxGroupFragment
              }
            }
            weight {
              ...WeightFragment
            }
            images {
              url
              id
            }
            attributes {
              attribute {
                id
                slug
                name
                description
                isRequired
                isVariant
                dataType
                choices {
                  id
                  slug
                  name
                }
                referenceType
              }
              value {
                id
                name
                value
              }
            }
          }
          categories {
            ...CategoryFragment
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${WeightFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${TaxGroupFragmentFragmentDoc}
  ${CategoryFragmentFragmentDoc}
`

/**
 * __useProductDraftsQuery__
 *
 * To run a query within a React component, call `useProductDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDraftsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProductDraftsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ProductDraftsQuery, Types.ProductDraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductDraftsQuery, Types.ProductDraftsQueryVariables>(ProductDraftsDocument, options)
}
export function useProductDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductDraftsQuery, Types.ProductDraftsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductDraftsQuery, Types.ProductDraftsQueryVariables>(
    ProductDraftsDocument,
    options
  )
}
export type ProductDraftsQueryHookResult = ReturnType<typeof useProductDraftsQuery>
export type ProductDraftsLazyQueryHookResult = ReturnType<typeof useProductDraftsLazyQuery>
export type ProductDraftsQueryResult = Apollo.QueryResult<Types.ProductDraftsQuery, Types.ProductDraftsQueryVariables>
export const ProductFamiliesDocument = gql`
  query productFamilies($language: Language!, $first: Int!, $filter: ProductFamilyFilterInput) {
    productFamilies(language: $language, first: $first, filter: $filter) {
      totalCount
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
`

/**
 * __useProductFamiliesQuery__
 *
 * To run a query within a React component, call `useProductFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFamiliesQuery({
 *   variables: {
 *      language: // value for 'language'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductFamiliesQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductFamiliesQuery, Types.ProductFamiliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductFamiliesQuery, Types.ProductFamiliesQueryVariables>(
    ProductFamiliesDocument,
    options
  )
}
export function useProductFamiliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductFamiliesQuery, Types.ProductFamiliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductFamiliesQuery, Types.ProductFamiliesQueryVariables>(
    ProductFamiliesDocument,
    options
  )
}
export type ProductFamiliesQueryHookResult = ReturnType<typeof useProductFamiliesQuery>
export type ProductFamiliesLazyQueryHookResult = ReturnType<typeof useProductFamiliesLazyQuery>
export type ProductFamiliesQueryResult = Apollo.QueryResult<
  Types.ProductFamiliesQuery,
  Types.ProductFamiliesQueryVariables
>
export const ProductListDocument = gql`
  query productList(
    $filter: ProductFilterInput
    $language: Language!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $marketplaceOnly: Boolean
    $storeId: ID
  ) {
    products(
      filter: $filter
      language: $language
      before: $before
      after: $after
      first: $first
      last: $last
      storeId: $storeId
      marketplaceOnly: $marketplaceOnly
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          slug
          name
          offers {
            catalogPrice {
              ...MoneyFragment
            }
            sellingPrice {
              ...TaxedMoneyFragment
            }
            taxRate
            discountRate
            quantity
            availability {
              ...ProductAvailabilityFragment
            }
          }
          media {
            ...MediaFragment
          }
        }
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${ProductAvailabilityFragmentFragmentDoc}
  ${MediaFragmentFragmentDoc}
`

/**
 * __useProductListQuery__
 *
 * To run a query within a React component, call `useProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      marketplaceOnly: // value for 'marketplaceOnly'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useProductListQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductListQuery, Types.ProductListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductListQuery, Types.ProductListQueryVariables>(ProductListDocument, options)
}
export function useProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductListQuery, Types.ProductListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductListQuery, Types.ProductListQueryVariables>(ProductListDocument, options)
}
export type ProductListQueryHookResult = ReturnType<typeof useProductListQuery>
export type ProductListLazyQueryHookResult = ReturnType<typeof useProductListLazyQuery>
export type ProductListQueryResult = Apollo.QueryResult<Types.ProductListQuery, Types.ProductListQueryVariables>
export const ProductsDocument = gql`
  query products(
    $filter: ProductFilterInput
    $language: Language!
    $before: String
    $after: String
    $first: Int
    $last: Int
    $marketplaceOnly: Boolean
    $storeId: ID
  ) {
    products(
      filter: $filter
      language: $language
      before: $before
      after: $after
      first: $first
      last: $last
      storeId: $storeId
      marketplaceOnly: $marketplaceOnly
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          slug
          name
          bodyHtml
          manufacturer {
            ...ManufacturerFragment
          }
          offers {
            sku
            store {
              ...StoreFragment
            }
            catalogPrice {
              ...MoneyFragment
            }
            sellingPrice {
              ...TaxedMoneyFragment
            }
            discountRate
            taxRate
            quantity
            availability {
              ...ProductAvailabilityFragment
            }
            defaultShippingMethod {
              ...StoreShippingMethodFragment
            }
          }
          subscribed
          weight {
            ...WeightFragment
          }
          media {
            ...MediaFragment
          }
          relatedProducts {
            type
            variants {
              description
              current
              value
              product {
                id
                slug
                name
                offers {
                  quantity
                }
              }
            }
          }
          coffeeInfo {
            origins {
              country
              processingType
            }
            format
            roastProfile
            brewingProfile
          }
        }
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${ManufacturerFragmentFragmentDoc}
  ${StoreFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${ProductAvailabilityFragmentFragmentDoc}
  ${StoreShippingMethodFragmentFragmentDoc}
  ${WeightFragmentFragmentDoc}
  ${MediaFragmentFragmentDoc}
`

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      marketplaceOnly: // value for 'marketplaceOnly'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProductsQuery, Types.ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(ProductsDocument, options)
}
export function useProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProductsQuery, Types.ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ProductsQuery, Types.ProductsQueryVariables>(ProductsDocument, options)
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>
export type ProductsQueryResult = Apollo.QueryResult<Types.ProductsQuery, Types.ProductsQueryVariables>
export const QuickbooksConfigurationDocument = gql`
  query quickbooksConfiguration {
    quickbooksConfiguration {
      id
      integration {
        ...IntegrationFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
`

/**
 * __useQuickbooksConfigurationQuery__
 *
 * To run a query within a React component, call `useQuickbooksConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbooksConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbooksConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickbooksConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.QuickbooksConfigurationQuery, Types.QuickbooksConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.QuickbooksConfigurationQuery, Types.QuickbooksConfigurationQueryVariables>(
    QuickbooksConfigurationDocument,
    options
  )
}
export function useQuickbooksConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.QuickbooksConfigurationQuery,
    Types.QuickbooksConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.QuickbooksConfigurationQuery, Types.QuickbooksConfigurationQueryVariables>(
    QuickbooksConfigurationDocument,
    options
  )
}
export type QuickbooksConfigurationQueryHookResult = ReturnType<typeof useQuickbooksConfigurationQuery>
export type QuickbooksConfigurationLazyQueryHookResult = ReturnType<typeof useQuickbooksConfigurationLazyQuery>
export type QuickbooksConfigurationQueryResult = Apollo.QueryResult<
  Types.QuickbooksConfigurationQuery,
  Types.QuickbooksConfigurationQueryVariables
>
export const SendcloudConfigurationDocument = gql`
  query SendcloudConfiguration {
    sendcloudConfiguration {
      id
      publicKey
      secretKey
      integration {
        ...IntegrationFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
`

/**
 * __useSendcloudConfigurationQuery__
 *
 * To run a query within a React component, call `useSendcloudConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendcloudConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendcloudConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useSendcloudConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.SendcloudConfigurationQuery, Types.SendcloudConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.SendcloudConfigurationQuery, Types.SendcloudConfigurationQueryVariables>(
    SendcloudConfigurationDocument,
    options
  )
}
export function useSendcloudConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SendcloudConfigurationQuery,
    Types.SendcloudConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.SendcloudConfigurationQuery, Types.SendcloudConfigurationQueryVariables>(
    SendcloudConfigurationDocument,
    options
  )
}
export type SendcloudConfigurationQueryHookResult = ReturnType<typeof useSendcloudConfigurationQuery>
export type SendcloudConfigurationLazyQueryHookResult = ReturnType<typeof useSendcloudConfigurationLazyQuery>
export type SendcloudConfigurationQueryResult = Apollo.QueryResult<
  Types.SendcloudConfigurationQuery,
  Types.SendcloudConfigurationQueryVariables
>
export const ShipStationConfigurationDocument = gql`
  query ShipStationConfiguration {
    shipstationConfiguration {
      id
      apiKey
      apiSecret
      integration {
        ...IntegrationFragment
      }
    }
  }
  ${IntegrationFragmentFragmentDoc}
`

/**
 * __useShipStationConfigurationQuery__
 *
 * To run a query within a React component, call `useShipStationConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipStationConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipStationConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useShipStationConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ShipStationConfigurationQuery,
    Types.ShipStationConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.ShipStationConfigurationQuery, Types.ShipStationConfigurationQueryVariables>(
    ShipStationConfigurationDocument,
    options
  )
}
export function useShipStationConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ShipStationConfigurationQuery,
    Types.ShipStationConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.ShipStationConfigurationQuery, Types.ShipStationConfigurationQueryVariables>(
    ShipStationConfigurationDocument,
    options
  )
}
export type ShipStationConfigurationQueryHookResult = ReturnType<typeof useShipStationConfigurationQuery>
export type ShipStationConfigurationLazyQueryHookResult = ReturnType<typeof useShipStationConfigurationLazyQuery>
export type ShipStationConfigurationQueryResult = Apollo.QueryResult<
  Types.ShipStationConfigurationQuery,
  Types.ShipStationConfigurationQueryVariables
>
export const StoreCategoriesDocument = gql`
  query storeCategories(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: StoreFilterInput
    $language: Language!
  ) {
    stores(before: $before, after: $after, first: $first, last: $last, filter: $filter) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          categories(language: $language) {
            ...CategoryFragment
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${CategoryFragmentFragmentDoc}
`

/**
 * __useStoreCategoriesQuery__
 *
 * To run a query within a React component, call `useStoreCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreCategoriesQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useStoreCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<Types.StoreCategoriesQuery, Types.StoreCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.StoreCategoriesQuery, Types.StoreCategoriesQueryVariables>(
    StoreCategoriesDocument,
    options
  )
}
export function useStoreCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.StoreCategoriesQuery, Types.StoreCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.StoreCategoriesQuery, Types.StoreCategoriesQueryVariables>(
    StoreCategoriesDocument,
    options
  )
}
export type StoreCategoriesQueryHookResult = ReturnType<typeof useStoreCategoriesQuery>
export type StoreCategoriesLazyQueryHookResult = ReturnType<typeof useStoreCategoriesLazyQuery>
export type StoreCategoriesQueryResult = Apollo.QueryResult<
  Types.StoreCategoriesQuery,
  Types.StoreCategoriesQueryVariables
>
export const StoreOrderDocument = gql`
  query storeOrder(
    $filter: VendorOrderFilterInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: OrderSortingInput
  ) {
    storeOrders(filter: $filter, before: $before, after: $after, first: $first, last: $last, sortBy: $sortBy) {
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        node {
          id
          number
          title
          status
          total {
            ...TaxedMoneyFragment
          }
          createdAt
          updatedAt
          fulfillmentStatus
          paymentStatus
          notes
          shippingMethod
          deliveryDate
          fulfillmentStatus
          store {
            id
            name
            legalName
            logo {
              url
            }
          }
          customer {
            email
            firstName
            lastName
            fullName
            phone
          }
          shippingAddress {
            street
            houseNumber
            city
            zipCode
            name
            country
            companyName
            email
            phone
          }
          billingAddress {
            street
            houseNumber
            city
            zipCode
            name
            country
            companyName
            taxId
          }
          paymentMethod {
            id
            payer {
              id
              user {
                email
                firstName
                lastName
                fullName
                phone
              }
            }
            gateway
            paymentType
          }
          payments {
            id
            paymentMethod {
              id
              payer {
                id
                user {
                  email
                  firstName
                  lastName
                  fullName
                  phone
                }
              }
              gateway
              paymentType
            }
            payer {
              id
              user {
                email
                firstName
                lastName
                fullName
                phone
              }
            }
            total {
              ...MoneyFragment
            }
            pspReference
            abandoned
            status
            gateway
            capturedAmount {
              ...MoneyFragment
            }
            createdAt
            updatedAt
            transactions {
              id
              token
              kind
              isSuccess
              total {
                ...MoneyFragment
              }
            }
          }
          activities {
            id
            event
            actor {
              fullName
              email
              phone
            }
            createdAt
            updatedAt
            mergedMetadata(
              keys: ["amount", "currency", "gateway", "payment_id", "payment_type", "invoice_id", "is_baselinker"]
            )
          }
          invoices {
            id
            number
            pdfFile {
              url
            }
            createdAt
            effectiveDate
            total {
              ...MoneyFragment
            }
          }
          items {
            id
            productName
            sku
            total {
              ...TaxedMoneyFragment
            }
            quantity
            type
          }
        }
        cursor
      }
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${TaxedMoneyFragmentFragmentDoc}
  ${MoneyFragmentFragmentDoc}
`

/**
 * __useStoreOrderQuery__
 *
 * To run a query within a React component, call `useStoreOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreOrderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useStoreOrderQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.StoreOrderQuery, Types.StoreOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.StoreOrderQuery, Types.StoreOrderQueryVariables>(StoreOrderDocument, options)
}
export function useStoreOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.StoreOrderQuery, Types.StoreOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.StoreOrderQuery, Types.StoreOrderQueryVariables>(StoreOrderDocument, options)
}
export type StoreOrderQueryHookResult = ReturnType<typeof useStoreOrderQuery>
export type StoreOrderLazyQueryHookResult = ReturnType<typeof useStoreOrderLazyQuery>
export type StoreOrderQueryResult = Apollo.QueryResult<Types.StoreOrderQuery, Types.StoreOrderQueryVariables>
export const StoresDocument = gql`
  query stores(
    $filter: StoreFilterInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $language: Language!
  ) {
    stores(filter: $filter, before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...StoreFragment
        }
      }
      totalCount
    }
  }
  ${PageInfoFragmentFragmentDoc}
  ${StoreFragmentFragmentDoc}
`

/**
 * __useStoresQuery__
 *
 * To run a query within a React component, call `useStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useStoresQuery(baseOptions: Apollo.QueryHookOptions<Types.StoresQuery, Types.StoresQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.StoresQuery, Types.StoresQueryVariables>(StoresDocument, options)
}
export function useStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.StoresQuery, Types.StoresQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.StoresQuery, Types.StoresQueryVariables>(StoresDocument, options)
}
export type StoresQueryHookResult = ReturnType<typeof useStoresQuery>
export type StoresLazyQueryHookResult = ReturnType<typeof useStoresLazyQuery>
export type StoresQueryResult = Apollo.QueryResult<Types.StoresQuery, Types.StoresQueryVariables>
export const TaxGroupsDocument = gql`
  query taxGroups(
    $filter: TaxGroupFilterInput
    $sortBy: TaxGroupSortingInput
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    taxGroups(first: $first, filter: $filter, sortBy: $sortBy, before: $before, after: $after, last: $last) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          name
          rate
          code
        }
      }
    }
  }
`

/**
 * __useTaxGroupsQuery__
 *
 * To run a query within a React component, call `useTaxGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useTaxGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TaxGroupsQuery, Types.TaxGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<Types.TaxGroupsQuery, Types.TaxGroupsQueryVariables>(TaxGroupsDocument, options)
}
export function useTaxGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TaxGroupsQuery, Types.TaxGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<Types.TaxGroupsQuery, Types.TaxGroupsQueryVariables>(TaxGroupsDocument, options)
}
export type TaxGroupsQueryHookResult = ReturnType<typeof useTaxGroupsQuery>
export type TaxGroupsLazyQueryHookResult = ReturnType<typeof useTaxGroupsLazyQuery>
export type TaxGroupsQueryResult = Apollo.QueryResult<Types.TaxGroupsQuery, Types.TaxGroupsQueryVariables>
